import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Modal } from 'antd';
import circlemem from '../../assets/images/circlemem.svg';
import arrow from '../../assets/images/arrow-right.svg';

const CompactMembershipCard = ({ item, setDetail, button, hideReadMore, hideView }) => {
  const [showMore, setShowMore] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const handleViewPackage = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  return (
    <div className="membership compact position-relative">
      <div className="header compactMembership" style={{ fontSize: '12px' }}>
        <div className="heading" style={{ fontSize: '14px', fontWeight: 'bold' }}>
          {item.title}
        </div>
        <div className={item.isActive ? 'status active' : 'status'} style={{ fontSize: '10px' }}>
          <span /> {item.isActive ? 'Active' : 'Inactive'}
        </div>
      </div>
      <div className="price" style={{ fontSize: '12px' }}>
        ${`${item.amount}/${item.interval}`}
      </div>
      <div className="compactDetail" style={{ fontSize: '10px' }}>
        {item.isSubscription ? 'Subscription Membership' : 'Wallet Membership'}
      </div>
      <div className="detail" style={{ fontSize: '12px' }}>
        {item?.description && ReactHtmlParser(item?.description)}
      </div>

      {!hideReadMore && (
        <div className="showMore-wrapper" style={{ fontSize: '10px' }}>
          {item?.description?.length > 250 && (
            <div className="show" onClick={() => setDetail(item)}>
              {showMore ? 'Read Less' : 'Read More'}
            </div>
          )}
        </div>
      )}

      <div className="d-flex w-100 justify-content-between">
        {!hideView && (
          <div style={{ display: 'flex', width: '100%', zIndex: 1000 }}>
            <button onClick={handleViewPackage} style={{ fontSize: '10px', height: '30px' }}>
              View Package
            </button>
          </div>
        )}
        <div className="viewcircle">
          {button && (
            <button onClick={() => setDetail(item)} style={{ fontSize: '10px', height: '30px' }}>
              {button ?? 'View'}
              <span>
                <img src={arrow} className="icon" style={{ marginLeft: '4px', marginTop: '-2px', width: '12px' }} />
              </span>
            </button>
          )}
        </div>
      </div>
      <img src={circlemem} className="circleimg1" style={{ width: '20px', height: '20px' }} />
      <img src={circlemem} className="circleimg2" style={{ width: '20px', height: '20px' }} />

      <Modal
        centered
        width={500}
        header={null}
        footer={null}
        visible={modalVisible}
        onCancel={handleCloseModal}
        className="membership-modal"
      >
        <div className="description-card" style={{ fontSize: '12px' }}>
          {item?.description && ReactHtmlParser(item?.description)}
        </div>
      </Modal>
    </div>
  );
};

export default CompactMembershipCard;
