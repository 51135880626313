const FormIcon = ({ val }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 25.994 25.994" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <g>
          <path
            fill={val ? '#003B5B' : 'white'}
            d="M21.815,0H4.18C3.707,0,3.326,0.382,3.326,0.853V25.14c0,0.472,0.381,0.854,0.853,0.854h13.083
                c0.21,0,0.413-0.077,0.57-0.218l4.55-4.072c0.181-0.162,0.285-0.394,0.285-0.636V0.853C22.667,0.382,22.285,0,21.815,0z
                M20.962,20.59h-3.97v3.648l-0.053,0.047H5.033V1.707h15.928V20.59z"
          />
          <path
            fill={val ? '#003B5B' : 'white'}
            d="M14.562,15.173h-4.337c-0.247,0-0.446,0.2-0.446,0.446v4.337c0,0.247,0.199,0.446,0.446,0.446
                h4.337c0.246,0,0.446-0.2,0.446-0.446V15.62C15.009,15.374,14.809,15.173,14.562,15.173z M14.118,19.511h-3.446v-3.446h3.446
                V19.511z"
          />
          <path
            fill={val ? '#003B5B' : 'white'}
            d="M14.953,8.569V8.537c0-0.218-0.177-0.394-0.394-0.394h-4.33c-0.217,0-0.394,0.176-0.394,0.394
                v4.329c0,0.219,0.177,0.395,0.394,0.395h4.331c0.217,0,0.394-0.176,0.394-0.395v-1.543l-0.696,0.696
                c-0.026,0.026-0.062,0.039-0.092,0.062v0.393h-3.543V8.93h3.543v0.426L14.953,8.569z"
          />
          <path
            fill={val ? '#003B5B' : 'white'}
            d="M15.764,8.313l-2.196,2.195l-0.84-0.841c-0.227-0.227-0.593-0.227-0.82,0
                c-0.227,0.227-0.227,0.594,0,0.819l1.251,1.252c0.113,0.113,0.262,0.17,0.41,0.17c0.149,0,0.298-0.057,0.41-0.17l2.605-2.606
                c0.228-0.227,0.228-0.593,0-0.82C16.358,8.086,15.991,8.086,15.764,8.313z"
          />
          <rect x="7.305" y="4.159" width="11.385" height="1.189" fill={val ? '#003B5B' : 'white'} />
        </g>
      </g>
    </svg>
  );
};

export default FormIcon;
