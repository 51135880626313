import React, { useEffect, useState } from 'react';
import { Button, Modal, message, List, Typography, Input } from 'antd';
import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import DashboardWrapper from '../../components/wrapper/DashboardWrapper';
import { createForm, deleteForm, listForm } from '@/api/customerapi';

const EmbeddableForms = () => {
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [newFormName, setNewFormName] = useState('');
  const [currentForm, setCurrentForm] = useState(null);

  const appUrl = window.location.origin;

  useEffect(() => {
    const fetchForms = async () => {
      try {
        const response = await listForm();
        const fetchedForms = response?.data?.output?.forms;
        setForms(Array.isArray(fetchedForms) ? fetchedForms : []);
      } catch (error) {
        message.error(error?.response?.data?.error?.message || 'Failed to fetch forms');
      } finally {
        setLoading(false);
      }
    };

    fetchForms();
  }, []);

  const handleOpenCreateModal = () => {
    setNewFormName('');
    setShowCreateModal(true);
  };

  const handleCreateForm = async () => {
    if (!newFormName.trim()) {
      message.error('Please enter a form name');
      return;
    }

    const newForm = { name: newFormName.trim() };
    try {
      const formRes = await createForm(newForm);
      if (formRes.status === 200) {
        message.success('Form created successfully!');
        setNewFormName('');
        setShowCreateModal(false);

        const updatedList = await listForm();
        const fetchedForms = updatedList?.data?.output?.forms;
        setForms(Array.isArray(fetchedForms) ? fetchedForms : []);
      }
    } catch (error) {
      message.error(error?.response?.data?.error?.message || 'Failed to create form');
    }
  };

  const handleDeleteForm = async (formId) => {
    const form = { id: formId };
    try {
      const deleteRes = await deleteForm(form);
      if (deleteRes.status === 200) {
        message.success('Form deleted successfully!');
        setForms((prevForms) => prevForms.filter((f) => f.id !== formId));
      }
    } catch (error) {
      message.error(error?.response?.data?.error?.message || 'Failed to delete form');
    }
  };

  const handleCopyCode = (form) => {
    setCurrentForm(form);
    setShowCopyModal(true);
  };

  const copyIframeCode = () => {
    if (!currentForm) return;
    const iframeCode = `<iframe src="${appUrl}/embedded-form?formId=${currentForm.id}" width="600" height="600" frameborder="0"></iframe>`;
    navigator.clipboard
      .writeText(iframeCode)
      .then(() => {
        message.success('Code copied to clipboard!');
      })
      .catch(() => {
        message.error('Failed to copy code. Please copy manually.');
      });
  };

  const renderEmptyState = () => (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <Typography.Title level={3}>No forms found</Typography.Title>
      <Button type="primary" onClick={handleOpenCreateModal}>
        Create New Form
      </Button>
    </div>
  );

  const renderFormsList = () => (
    <div style={{ margin: '20px' }}>
      <Typography.Title level={2}>Your Embeddable Forms</Typography.Title>
      <List
        dataSource={forms}
        renderItem={(form) => (
          <List.Item
            actions={[
              <Button icon={<CopyOutlined />} onClick={() => handleCopyCode(form)}>
                Copy code
              </Button>,
              <Button icon={<DeleteOutlined />} danger onClick={() => handleDeleteForm(form.id)}>
                Delete
              </Button>,
            ]}
          >
            <List.Item.Meta title={form.name} description={`Form ID: ${form.id}`} />
          </List.Item>
        )}
      />
      <Button type="primary" style={{ marginTop: '20px' }} onClick={handleOpenCreateModal}>
        Create New Form
      </Button>
    </div>
  );

  return (
    <DashboardWrapper>
      {loading ? (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>Loading...</div>
      ) : (
        <>
          {forms.length === 0 ? renderEmptyState() : renderFormsList()}
          <Modal
            title="Copy Your Form Code"
            visible={showCopyModal}
            onCancel={() => setShowCopyModal(false)}
            footer={[
              <Button onClick={copyIframeCode} type="primary">
                Copy Code
              </Button>,
              <Button onClick={() => setShowCopyModal(false)}>Close</Button>,
            ]}
          >
            {currentForm && (
              <>
                <Typography.Paragraph>Embed this iframe code into your website:</Typography.Paragraph>
                <Input.TextArea
                  style={{ fontFamily: 'monospace', color: '#333' }}
                  rows={4}
                  value={`<iframe src="${appUrl}/embedded-form?formId=${currentForm.id}" width="600" height="600" frameborder="0"></iframe>`}
                  readOnly
                />
              </>
            )}
          </Modal>

          <Modal
            title="Create a New Form"
            visible={showCreateModal}
            onCancel={() => setShowCreateModal(false)}
            onOk={handleCreateForm}
          >
            <Input placeholder="Enter form name" value={newFormName} onChange={(e) => setNewFormName(e.target.value)} />
          </Modal>
        </>
      )}
    </DashboardWrapper>
  );
};

export default EmbeddableForms;
