import React, { useState } from 'react';
import { Button, message } from 'antd';
import { buyPublicCustomerMembership, sendPublicWelcomeEmail } from '@/api/publicapi';
import CompactMembershipCard from '@/pages/membershippackage/CompactMembershipCard';
import './style.less';

const SummaryStep = ({ user, paymentMethod, membership, existingCustomer, setLoading, formId, onResetForm }) => {
  const [isSubscriptionConfirmed, setIsSubscriptionConfirmed] = useState(false);

  const handleConfirmation = async () => {
    setLoading(true);
    try {
      const membershipData = {
        userId: user._id,
        membershipId: membership._id,
        formId: formId,
      };
      const res = await buyPublicCustomerMembership(membershipData);
      if (res?.data?.status === 200) {
        if (!existingCustomer) {
          const welcomeEmail = await sendPublicWelcomeEmail({ email: user.email });
          if (welcomeEmail?.data.status === 200) {
            setLoading(false);
            message.success('Customer successfully subscribed');
            setIsSubscriptionConfirmed(true);
          }
        } else {
          setLoading(false);
          message.success('Customer successfully subscribed');
          setIsSubscriptionConfirmed(true);
        }
      }
    } catch (error) {
      setLoading(false);
      message.error(error?.response?.data?.error?.message || 'Error occurred');
    }
  };

  if (isSubscriptionConfirmed) {
    return (
      <div className="stepContainer">
        <h2>Subscription Successful!</h2>
        <p>Your membership subscription was processed successfully.</p>
        <hr />
        <h3>Summary</h3>
        <p>
          <strong>Name:</strong> {user.firstName} {user.lastName}
        </p>
        <p>
          <strong>Email:</strong> {user.email}
        </p>
        <p>
          <strong>Membership:</strong> {membership?.title}
        </p>
        <p>
          <strong>Payment:</strong>
          {paymentMethod?.brand?.toUpperCase()} **** {paymentMethod?.last4}
        </p>
        <hr />
        <Button type="primary" onClick={onResetForm} block>
          Start Over
        </Button>
      </div>
    );
  }

  return (
    <div className="stepContainer">
      <h2>Summary</h2>
      <hr />
      <h3>Customer Details</h3>
      <p>
        <strong>Name:</strong> {user.firstName} {user.lastName}
      </p>
      <p>
        <strong>Email:</strong> {user.email}
      </p>
      <hr />
      <h3>Payment Method</h3>
      <p>
        <strong>Card:</strong> {paymentMethod?.brand?.toUpperCase()} **** {paymentMethod?.last4}
      </p>
      <hr />
      <h3>Membership</h3>
      <CompactMembershipCard item={membership} hideReadMore />
      <p>
        <strong>Price:</strong> {membership?.amount}
      </p>
      <hr />
      <Button type="primary" onClick={handleConfirmation} block>
        Confirm Subscription
      </Button>
    </div>
  );
};

export default SummaryStep;
