
export const handleRouting = (screen, isPaid, navigate) => {
  if (screen === 1) {
    // if user didn't complete profile
    navigate('/profiledetails')
  } else if (screen === 2 && !isPaid) {
    // if user didn't complete bank detail and he did not subscribe
    navigate('/paymentdetail')
  } else if (screen === 2 && isPaid) {
    // if user didn't complete bank detail and he subscribed
    navigate('/paymentdetail')
  } else if (screen === 3 && !isPaid) {
    // if user complete bank detail and he didn't subscribed
    navigate('/subscription')
  } else if (screen === 3 && isPaid) {
    // if user complete everything and he also subscribed
    if (['/search-customers', '/subscribed-customers', '/membership-packages', '/add-customer', '/news-feed', '/reports', '/settings', '/embeddable-forms', '/embedded-form'].includes(location.pathname)) return;
    navigate('/membership-packages')
  }
}
