import React, { Suspense, useEffect, useState } from 'react';
// import Layout from "./pages/Layout";
import { Route, Routes, useNavigate } from 'react-router-dom';
import routes from './routes/routes';
import { useDispatch, useSelector } from 'react-redux';
import { handleRouting } from './common/helper/handleRouting';
import Sidebar from './components/Sidebar';
import useNetworkStatus from './useNetworkStatus';
import { message, ConfigProvider } from 'antd';
import { handleAddCount } from './common/helper/handleAddCount';
import { homeNotificationApi } from './api/notificationapi';
import { setIsRead } from './store/loginslice/LoginSlice';
import theme from './theme';

const App = () => {
  const userData = useSelector((state) => state.user?.user);
  const navigate = useNavigate();
  const isOnline = useNetworkStatus();
  const token = localStorage.getItem('token');
  const unauthorized = localStorage.getItem('unauthorized');
  const dispatch = useDispatch();

  const callNotificationApi = async () => {
    const resp = await homeNotificationApi();
    const val = resp.data.output.unReadNotificationCount;
    if (val > 0) {
      dispatch(setIsRead(true));
    }
  };

  useEffect(() => {
    if (token && userData) {
      handleRouting(userData.screen, userData.isPaid, navigate);
      handleAddCount(dispatch);
      callNotificationApi();
    }
    if (!token && unauthorized) {
      message.error('You are logged in another device');
      setTimeout(() => {
        localStorage.removeItem('unauthorized');
      }, 1500);
    }
  }, [token]);

  return (
    <>
      {/* <Offline>
        <div className='text-center' style={{ height: "40px", width: "100%", background: "rgb(255 0 0 / 62%)", color: "#fff", fontWeight: "600",position:"absolute",zIndex: "99"}}>
          <p className='pt-2'> You are offline! please check your connection. </p>
        </div>
      </Offline> */}
      {/* {isOnline ? null : (
        <div className='text-center' style={{ height: "40px", width: "100%", background: "rgb(255 0 0 / 62%)", color: "#fff", fontWeight: "600", position: "absolute", zIndex: "99" }}>
          <p className='pt-2'> You are offline! please check your connection. </p>
        </div>
      )} */}
      <div className="d-flex">
        {[
          '/search-customers',
          '/subscribed-customers',
          '/membership-packages',
          '/add-customer',
          '/news-feed',
          '/reports',
          '/embeddable-forms',
          '/settings',
        ].includes(location.pathname) && <Sidebar />}

        <ConfigProvider theme={theme}>
          <Routes>
            {routes.map((route) => (
              <Route key={route.path} path={route.path} element={route.element()} />
            ))}
          </Routes>
        </ConfigProvider>
      </div>
    </>
  );
};

export default App;
