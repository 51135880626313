import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { addPublicCustomerCard, getPublicCustomerProfile } from '@/api/publicapi';
import './style.less';

const PaymentDetailsStep = ({ user, onSuccess, setUser, setLoading, formId }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [form] = Form.useForm();
  const [cardName, setCardName] = useState('');

  const handleSubmit = async () => {
    if (!stripe || !elements) return;
    setLoading(true);
    try {
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
      });

      if (error) {
        message.error(error.message);
        setLoading(false);
        return;
      }

      if (paymentMethod.card.funding !== 'credit' && paymentMethod.card.funding !== 'debit') {
        setLoading(false);
        message.error('Only credit or debit cards allowed');
        return;
      }

      const payload = {
        name: cardName,
        brand: paymentMethod.card.brand,
        last4: paymentMethod.card.last4,
        funding: paymentMethod.card.funding,
        expMonth: paymentMethod.card.exp_month.toString(),
        expYear: paymentMethod.card.exp_year.toString(),
        paymentMethodId: paymentMethod.id,
        userId: user._id,
        formId: formId,
      };

      const res = await addPublicCustomerCard(payload);
      if (res.data?.status === 200) {
        const userRes = await getPublicCustomerProfile({ userId: user._id, formId: formId });
        if (userRes.data?.status === 200) {
          setUser(userRes.data.output.userData);
          onSuccess(res.data.output.cardData);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error?.response?.data?.error?.message || 'Error occurred');
    }
  };

  return (
    <div className="stepContainer">
      <h2>Payment Details</h2>
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          name="cardName"
          label="Name on Card"
          rules={[
            { required: true, message: 'Please enter name on card' },
            { pattern: /^[A-Za-z ]+$/, message: 'Only letters allowed' },
          ]}
        >
          <Input value={cardName} onChange={(e) => setCardName(e.target.value)} placeholder="Name on Card" />
        </Form.Item>
        <Form.Item label="Card Number" required>
          <CardNumberElement className="card-element" />
        </Form.Item>
        <Form.Item label="Expiry" required>
          <CardExpiryElement className="card-element" />
        </Form.Item>
        <Form.Item label="CVC" required>
          <CardCvcElement className="card-element" />
        </Form.Item>
        <Button type="primary" htmlType="submit" block>
          Continue
        </Button>
      </Form>
    </div>
  );
};

export default PaymentDetailsStep;
