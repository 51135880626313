import React, { useState } from 'react';
import { Steps, message, Spin } from 'antd';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useLocation } from 'react-router-dom';

import SelectMembershipStep from './steps/SelectMembershipStep';
import CustomerDetailsStep from './steps/CustomerDetailsStep';
import PaymentDetailsStep from './steps/PaymentDetailsStep';
import SummaryStep from './steps/SummaryStep';

import bnkdbluelogo from '../../assets/images/bnkdbluelogo.png';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const EmbeddableSignUpForm = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  // const { formId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const formId = queryParams.get('formId');
  const [user, setUser] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [membership, setMembership] = useState(null);
  const [existingCustomer, setExistingCustomer] = useState(false);

  const steps = [
    { title: 'Select Membership' },
    { title: 'Customer Details' },
    { title: 'Payment Details' },
    { title: 'Summary' },
  ];

  const goToStep = (idx) => {
    setCurrentStep(idx);
  };

  const resetFormData = () => {
    setUser(null);
    setPaymentMethod(null);
    setMembership(null);
    setExistingCustomer(false);
    setCurrentStep(0);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <SelectMembershipStep
            formId={formId}
            onSelect={(mem) => {
              setMembership(mem);
              goToStep(1);
            }}
            setLoading={setLoading}
          />
        );
      case 1:
        return (
          <CustomerDetailsStep
            formId={formId}
            onSuccess={(userData, existingUser) => {
              setUser(userData);
              setExistingCustomer(existingUser);
              goToStep(2);
            }}
            setLoading={setLoading}
          />
        );
      case 2:
        return (
          <Elements stripe={stripePromise}>
            <PaymentDetailsStep
              formId={formId}
              user={user}
              onSuccess={(paymentData) => {
                setPaymentMethod(paymentData);
                goToStep(3);
              }}
              setUser={setUser}
              setLoading={setLoading}
            />
          </Elements>
        );
      case 3:
        return (
          <SummaryStep
            formId={formId}
            user={user}
            paymentMethod={paymentMethod}
            membership={membership}
            existingCustomer={existingCustomer}
            setLoading={setLoading}
            onResetForm={resetFormData}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div
      style={{
        maxWidth: 600,
        height: '100%',
        margin: '0 auto',
        fontFamily: 'Arial, sans-serif',
        padding: 15,
        overflowY: 'auto',
      }}
    >
      <div className="bnkdformimg">
        <img src={bnkdbluelogo} alt="bnkdimg" style={{ width: '100px', height: '100%' }} />
      </div>
      <Spin spinning={loading}>
        <Steps
          className="custom-steps"
          current={currentStep}
          items={steps.map((s) => ({ title: <span style={{ fontSize: '12px' }}>{s.title}</span> }))}
          style={{ marginBottom: 24 }}
        />
        {renderStep()}
      </Spin>
    </div>
  );
};

export default EmbeddableSignUpForm;
