import { useDispatch } from 'react-redux';
import { addCount } from '../../store/loginslice/LoginSlice';

export const handleAddCount = (dispatch) => {
  if (location.pathname.includes('/search-customers')) {
    dispatch(addCount(1));
  } else if (location.pathname.includes('/subscribed-customers')) {
    dispatch(addCount(2));
  } else if (location.pathname.includes('/membership-packages')) {
    dispatch(addCount(3));
  } else if (location.pathname.includes('/news-feed')) {
    dispatch(addCount(4));
  } else if (location.pathname.includes('/reports')) {
    dispatch(addCount(5));
  } else if (location.pathname.includes('/embeddable-forms')) {
    dispatch(addCount(6));
  } else if (location.pathname.includes('/settings')) {
    dispatch(addCount(7));
  }
};
