import instance from '../config/axios';

export async function customerPublicSignup(data) {
  return instance.post(`${process.env.REACT_APP_BASE_URL}public/signup`, data);
}

export async function createPublicCustomerProfile(data) {
  return instance.post(`${process.env.REACT_APP_BASE_URL}public/create-customer-profile`, data);
}

export async function getPublicCustomerProfileByEmail(email) {
  return instance.get(`${process.env.REACT_APP_BASE_URL}public/get-customer-profile-by-email?email=${email}`);
}

export async function getPublicCustomerProfile(data) {
  return instance.post(`${process.env.REACT_APP_BASE_URL}public/get-customer-profile`, data);
}

export async function addPublicCustomerCard(data) {
  return instance.post(`${process.env.REACT_APP_BASE_URL}public/add-customer-card`, data);
}

export async function buyPublicCustomerMembership(data) {
  return instance.post(`${process.env.REACT_APP_BASE_URL}public/buy-customer-membership`, data);
}

export async function sendPublicWelcomeEmail(data) {
  return instance.post(`${process.env.REACT_APP_BASE_URL}public/send-welcome-email`, data);
}

export async function listPublicMembership(page) {
  return instance.post(`${process.env.REACT_APP_BASE_URL}public/list`, page);
}
