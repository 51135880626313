import React from 'react';
import load from '../common/component/LazyLoading';
import PrivatePath from '../common/helper/PrivatePath';
import TempPrivatePath from '../common/helper/TempPrivatePath';

import { lazyWithRetry } from './lazyWithRetry';
const SignUp = load(() => import('../pages/Signup/Signup'));
const ProfileDetails = load(() => import('../pages/details'));
const PaymentDetail = load(() => import('../pages/paymentdetail/index'));
const BusinessInfo = load(() => import('../pages/businessInfo/index'));
const Subscription = load(() => import('../pages/subscription/index'));
const Login = load(() => import('../pages/Login/index'));
const CheckYourMail = load(() => import('../pages/Login/checkyourmail'));
const ForgotPassword = load(() => import('../pages/Login/forgotpassword'));
const Privacy = load(() => import('../pages/privacyandpolicy/Privacy'));
const TermsandConditions = load(() => import('../pages/privacyandpolicy/TermsAndConditions'));

// dashboard page components
// const Settings = lazyWithRetry(() => import('../pages/settings/index'));
// const NewsFeed = lazyWithRetry(() => import('../pages/newsfeed/index'));
// const Reports = lazyWithRetry(() => import('../pages/reports/index'));
// const MembershipPackage = lazyWithRetry(() => import('../pages/membershippackage/index'));
// const SubscribedCustomers = lazyWithRetry(() => import('../pages/subscribedcustomers/index'));
// const Customers = lazyWithRetry(() => import('../pages/customers/index'));

import Settings from '../pages/settings/index';
import EmbeddableForms from '../pages/embeddable-forms/index';
import EmbeddableSignUpForm from '../pages/embedded-form/EmbeddableSignUpForm';
import NewsFeed from '../pages/newsfeed/index';
import Reports from '../pages/reports/index';
import MembershipPackage from '../pages/membershippackage/index';
import SubscribedCustomers from '../pages/subscribedcustomers/index';
import Customers from '../pages/customers/index';
import CheckOnline from '../common/helper/CheckOnline';
import AddCustomer from '../pages/AddCustomer';
import ResetPassword from '@/pages/Login/resetpassword';

const routes = [
  {
    path: '/profiledetails',
    element: (props = {}) => (
      <PrivatePath>
        {' '}
        <CheckOnline />
        <ProfileDetails {...props} />
      </PrivatePath>
    ),
  },
  {
    path: '/paymentdetail',
    element: (props = {}) => (
      <PrivatePath>
        {' '}
        <CheckOnline />
        <PaymentDetail {...props} />
      </PrivatePath>
    ),
  },
  {
    path: '/businessinfo',
    element: (props = {}) => (
      <PrivatePath>
        {' '}
        <BusinessInfo {...props} />
      </PrivatePath>
    ),
  },
  {
    path: '/subscription',
    element: (props = {}) => (
      <PrivatePath>
        {' '}
        <CheckOnline />
        <Subscription {...props} />
      </PrivatePath>
    ),
  },
  {
    path: '/signup',
    element: (props = {}) => (
      <>
        {' '}
        <CheckOnline />
        <SignUp {...props} />
      </>
    ),
  },
  {
    path: '/forgotpassword',
    element: (props = {}) => (
      <>
        <CheckOnline />
        <ForgotPassword {...props} />
      </>
    ),
  },
  {
    path: '/resetpassword',
    element: (props = {}) => (
      <>
        <CheckOnline />
        <ResetPassword {...props} />
      </>
    ),
  },
  {
    path: '/checkmail',
    element: (props = {}) => (
      <>
        {' '}
        <CheckOnline />
        <CheckYourMail {...props} />
      </>
    ),
  },
  {
    path: '/',
    element: (props = {}) => (
      <>
        <CheckOnline />
        <Login {...props} />
      </>
    ),
  },
  {
    path: '/privacypolicy',
    element: (props = {}) => <Privacy {...props} />,
  },
  {
    path: '/termsandconditions',
    element: (props = {}) => <TermsandConditions {...props} />,
  },
  {
    path: '/search-customers',
    element: (props = {}) => (
      <PrivatePath>
        <CheckOnline /> <Customers {...props} />
      </PrivatePath>
    ),
  },
  {
    path: '/add-customer',
    element: (props = {}) => (
      <PrivatePath>
        <CheckOnline />
        <AddCustomer {...props} />
      </PrivatePath>
    ),
  },
  {
    path: '/subscribed-customers',
    element: (props = {}) => (
      <PrivatePath>
        <CheckOnline />
        <SubscribedCustomers {...props} />
      </PrivatePath>
    ),
  },
  {
    path: '/membership-packages',
    element: (props = {}) => (
      <PrivatePath>
        <CheckOnline />
        <MembershipPackage {...props} />
      </PrivatePath>
    ),
  },
  {
    path: '/settings',
    element: (props = {}) => (
      <PrivatePath>
        <CheckOnline />
        <Settings {...props} />
      </PrivatePath>
    ),
  },
  {
    path: '/news-feed',
    element: (props = {}) => (
      <PrivatePath>
        <CheckOnline />
        <NewsFeed {...props} />
      </PrivatePath>
    ),
  },
  {
    path: '/reports',
    element: (props = {}) => (
      <PrivatePath>
        <CheckOnline />
        <Reports {...props} />
      </PrivatePath>
    ),
  },
  {
    path: '/embeddable-forms',
    element: (props = {}) => (
      <TempPrivatePath>
        <CheckOnline />
        <EmbeddableForms {...props} />
      </TempPrivatePath>
    ),
  },
  {
    path: '/embedded-form',
    element: (props = {}) => (
      <div>
        <CheckOnline />
        <EmbeddableSignUpForm {...props} />
      </div>
    ),
  },
];

export default routes;
