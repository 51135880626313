import React, { useState } from 'react';
import { Form, Input, Button, Modal, message } from 'antd';
import { customerPublicSignup, createPublicCustomerProfile, getPublicCustomerProfileByEmail } from '@/api/publicapi';
import { generateRandomPassword } from '../utils';
import './style.less';

const CustomerDetailsStep = ({ onSuccess, setLoading, formId }) => {
  const [form] = Form.useForm();
  const [showExistingUser, setShowExistingUser] = useState(false);

  const handleFinish = async (values) => {
    setLoading(true);
    try {
      const signupData = {
        email: values.email,
        password: generateRandomPassword(),
        formId: formId,
      };
      const signupRes = await customerPublicSignup(signupData);

      if (signupRes.status === 200) {
        const output = signupRes.data.output;
        const profileData = {
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: values.phoneNumber,
          isConfirmed: true,
          userId: output.userId,
          formId: formId,
        };

        const profileRes = await createPublicCustomerProfile(profileData);
        if (profileRes.status === 200) {
          const userProfile = profileRes.data.output.userDetails;
          setLoading(false);
          onSuccess(userProfile, false);
        }
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.error?.errorCode === 1) {
        setShowExistingUser(true);
      } else {
        message.error(error?.response?.data?.error?.message || 'Error occurred');
      }
    }
  };

  const confirmExistingUser = async () => {
    const email = form.getFieldValue('email');
    setLoading(true);
    try {
      const profileRes = await getPublicCustomerProfileByEmail(email);
      if (profileRes.status === 200) {
        const userProfile = profileRes.data.output.userData;
        setLoading(false);
        onSuccess(userProfile, true);
      }
    } catch (error) {
      setLoading(false);
      message.error(error?.response?.data?.error?.message);
    }
  };

  return (
    <div className="stepContainer stepBottom">
      <h2>Customer Details</h2>
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Form.Item name="firstName" label="First Name" rules={[{ required: true, message: 'Please enter first name' }]}>
          <Input placeholder="First Name" />
        </Form.Item>
        <Form.Item name="lastName" label="Last Name" rules={[{ required: true, message: 'Please enter last name' }]}>
          <Input placeholder="Last Name" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: 'Please enter email' },
            { type: 'email', message: 'Invalid email' },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="phoneNumber"
          label="Phone Number"
          rules={[{ required: true, message: 'Please enter phone number' }]}
        >
          <Input placeholder="403-123-4567" />
        </Form.Item>
        <Button type="primary" htmlType="submit" block>
          Continue
        </Button>
      </Form>
      <Modal
        title="User Already Exists"
        centered
        open={showExistingUser}
        onOk={confirmExistingUser}
        onCancel={() => setShowExistingUser(false)}
        okText="Proceed"
      >
        <p>A user with that email address already exists. Continue with existing user?</p>
      </Modal>
    </div>
  );
};

export default CustomerDetailsStep;
