import React, { useState, useEffect } from 'react';
import { Button, message } from 'antd';
import { listPublicMembership } from '@/api/publicapi';
import CompactMembershipCard from '@/pages/membershippackage/CompactMembershipCard';
import './style.less';

const SelectMembershipStep = ({ onSelect, setLoading, formId }) => {
  const [membershipList, setMembershipList] = useState([]);

  useEffect(() => {
    fetchMemberships();
  }, []);

  const fetchMemberships = async () => {
    if (!formId) {
      message.error('Form ID is required to list memberships.');
      return;
    }

    setLoading(true);
    try {
      const res = await listPublicMembership({
        page: 1,
        pageLimit: 20,
        isActive: true,
        formId: formId,
      });
      if (res.status === 200) {
        setMembershipList(res.data?.output?.list || []);
      }
    } catch (e) {
      message.error('Failed to load memberships');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="stepContainer">
      <h2>Select Membership</h2>

      <div className="membershipFlex">
        {membershipList?.map((item) => (
          <div className="membershipItem">
            <CompactMembershipCard item={item} setDetail={() => onSelect(item)} button="Select" hideReadMore />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectMembershipStep;
